import { CalculatorConfig } from "./calculators";

export class Site {
    display: string;
    key: string;
    code: string;
}
export class CalculatorSiteConfig {
    calculatorGuid: string;
    calculatorVersion: number;
    key: string;
    config: { [key: string]: any };
    siteCalculator: CalculatorConfig;
    version: number;
    isLatest: boolean;
    constructor(calcGuid: string, calcVersion: number, sitekey: string) {
        this.calculatorGuid = calcGuid;
        this.calculatorVersion = calcVersion;
        this.key = sitekey;
        this.config = {};
    }

}

export class SiteInfo extends Site {
    siteInfo?: SiteInformation;
    constructor(site: Site) {
        super();
        this.display = site?.display;
        this.key = site?.key;
        this.code = site?.code;
        this.siteInfo = new SiteInformation();
    }
}

export class SiteInformation {
    basicInfo: BasicSiteInfo;
    assumbtions: SiteAssumbtion;
    defaultCharge: DefaultSiteCharge;
    priceFactors: SitePriceFactors = new SitePriceFactors();
    sizeFactor: SizeFactor = new SizeFactor();
    constructor() {
        this.basicInfo = new BasicSiteInfo();
        this.assumbtions = new SiteAssumbtion();
        this.defaultCharge = new DefaultSiteCharge();
        this.priceFactors = new SitePriceFactors();
    }
}

export class BasicSiteInfo {
    address: string;
    footer1: string;
    footer2: string;
    footer3: string;
    constructor() {
        this.address = null;
        this.footer1 = null;
        this.footer2 = null;
        this.footer3 = null;
    }
}

export class DefaultSiteCharge {
    minPurchaseOrder: number;
    percentageAfterFirstMinLot: number;
    charges: { [key: string]: SiteCharge };
    constructor() {
        this.minPurchaseOrder = null;
        this.percentageAfterFirstMinLot = null;
        this.charges = {};
    }
}

export class SiteCharge {
    name: string;
    type: 'Percent' | 'Fixed' | null;
    value: number;
    order?: number;
    constructor() {
        this.name = null;
        this.type = null;
        this.value = null;
    }
}

export class SiteAssumbtion {
    abrassiveClean: SiteProcessCategory;
    anodize: SiteProcessCategory;
    assemblyCleanAndPick: SiteProcessCategory;
    generalPainting: SiteProcessCategory;
    masking: SiteProcessCategory;
    ndt: SiteProcessCategory;
    plating: SiteProcessCategory;
    pylons: SiteProcessCategory;
    shotPeen: SiteProcessCategory;
    tiSSProcessing: SiteProcessCategory;
    constructor() {
        this.abrassiveClean = new SiteProcessCategory();
        this.anodize = new SiteProcessCategory();
        this.assemblyCleanAndPick = new SiteProcessCategory();
        this.generalPainting = new SiteProcessCategory();
        this.masking = new SiteProcessCategory();
        this.ndt = new SiteProcessCategory();
        this.plating = new SiteProcessCategory();
        this.pylons = new SiteProcessCategory();
        this.shotPeen = new SiteProcessCategory();
        this.tiSSProcessing = new SiteProcessCategory();
    }
}

export class SiteProcessCategory {
    laborRate: number;
    minLot: number;
    activeInactive: number;
    constructor() {
        this.laborRate = 0;
        this.minLot = 0;
        this.minLot = 0;
    }
}
export class SitePriceFactors {
    inflationFactor: InflationFactor;
    specification: Specification;
    competition: Competition;
    standardMargins: StandardMargins;
    constructor() {
        this.inflationFactor = new InflationFactor();
        this.specification = new Specification();
        this.competition = new Competition();
        this.standardMargins = new StandardMargins();
    }
}
export class InflationFactor {
    high: number;
    standard: number;
    low: number;
    constructor() {
        this.high = 0;
        this.standard = 0;
        this.low = 0;
    }
}
export class Specification {
    challenging: number;
    typical: number;
    complex: number;
    constructor() {
        this.challenging = 0;
        this.typical = 0;
        this.complex = 0;
    }
}
export class Competition {
    soleSource: number;
    typical: number;
    high: number;
    constructor() {
        this.high = 0;
        this.typical = 0;
        this.soleSource = 0;
    }
}
export class StandardMargins {
    labor: number;
    material: number;
    constructor() {
        this.labor = 0;
        this.material = 0;
    }
}
export class SizeFactor {
    small: number;
    medium: number;
    large: number;
    constructor() {
        this.small = 0;
        this.medium = 0;
        this.large = 0;
    }
}
