// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    isDevelopment: false,
    production: true,
    APIEndpoint: 'https://ignite.dev.jo.capella.io/',
    prctl: {
        GraphQlURL: 'https://prctl-graphql.ignite.dev.jo.capella.io/v1/graphql'
    },
    VERSION: require('../../package.json').version,
    auth: {
        API: 'https://apps.dev.jo.capella.io/',
        logoutURL: '',
        loginURL: 'https://apps.dev.jo.capella.io/',
        productKey: 'prctl'
    },
    pricingV1: {
        URL: "https://pricing.valencesurfacetech.net/new-estimate?estimateId="
    }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
