import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, of } from 'rxjs';
import { Site, SiteInfo } from '../models/site';
import { UniversalConfigService } from './universal-config.service';
import { HttpClient } from '@angular/common/http';
import { debounceTime, take } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SitePermission, SitePermissionService } from './site-permission.service';

function compare(a, b) {
    if (a.display < b.display) {
        return -1;
    }
    if (a.display > b.display) {
        return 1;
    }
    return 0;
}

export const ALL_SITES: SiteInfo = {
    code: 'ALLSITES',
    display: 'All Sites',
    key: 'all-sites',
    siteInfo: null
}
export class SelectedSitePermission {
    isAdmin: boolean;
    isEstimator: boolean;
}
const SITE_INFO_API = "api/site/info"
export const DISABLED_ROUTES = [`/prctl/estimates/`]
export const ACTIVATOR_SITES_ROUTES = [`/prctl/calculators/{{guid}}`]

@Injectable({ providedIn: 'root' })
export class SiteControllerService {

    public oCurrentSite: Observable<SiteInfo>;
    private _$currentSite: ReplaySubject<SiteInfo> = new ReplaySubject<Site>();

    public oAllSites: Observable<Site[]>;
    private _$allSites: ReplaySubject<Site[]> = new ReplaySubject<Site[]>();


    public oShowActivator: Observable<boolean>;
    private _$showActivator: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    public oIsCalcConfigured: Observable<boolean>;
    private _$isCalcConfigured: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public oSelectedSitePermission: Observable<SelectedSitePermission>;
    private _$selectedSitePermission: BehaviorSubject<SelectedSitePermission>
        = new BehaviorSubject<SelectedSitePermission>(null);

    public oEnterprise: Observable<boolean>;
    private _$oEnterprise: ReplaySubject<boolean> = new ReplaySubject<boolean>();


    constructor(
        private _universalConfigService: UniversalConfigService,
        private _httpClient: HttpClient,
        private _snackbar: MatSnackBar,
        public sitePermissionService: SitePermissionService

    ) {
        this.oCurrentSite = this._$currentSite.asObservable();
        this.oAllSites = this._$allSites.asObservable();
        this.oShowActivator = this._$showActivator.asObservable();
        this.oIsCalcConfigured = this._$isCalcConfigured.asObservable();
        this.oSelectedSitePermission = this._$selectedSitePermission.asObservable();
        this.oEnterprise = this._$oEnterprise.asObservable();
    }

    public async setCurrent(site: Site) {
        if (site?.code !== ALL_SITES.code) {
            const siteInfo = await this._httpClient.get<SiteInfo>(`${SITE_INFO_API}/${site?.key}`).toPromise() || new SiteInfo(site);

            this._$currentSite.next(siteInfo);
            let permissions = (await this.sitePermissionService.oSitePermissions.pipe(debounceTime(0), take(1)).toPromise()).sort(compare);
            this._$selectedSitePermission.next(
                {
                    isAdmin: !!permissions.find(per => per?.siteCode === site?.code && per?.level == 'ADMIN'),
                    isEstimator: !!permissions.find(per => per?.siteCode === site?.code && per?.level == 'ESTIMATOR'),
                })
        } else {
            this._$currentSite.next(ALL_SITES);
            this._$selectedSitePermission.next(
                {
                    isAdmin: false,
                    isEstimator: false,
                })
        }
    }

    public async getSites() {
        try {
            const allSites = (await this._universalConfigService.getUniversalConfig('Sites')).sort(compare);
            const permissions = (await this.sitePermissionService.oSitePermissions.pipe(debounceTime(0), take(1)).toPromise());
            const test = (await this.sitePermissionService.oEnterPrisePermissions.pipe(debounceTime(0), take(1)).toPromise());
            const availableSites = test ? allSites : allSites.filter(site => permissions.find(per => per.siteCode === site.code))
            this._$allSites.next(availableSites);
        } catch (ex) {
            console.error('Error in getSites:', ex);
            return of();
        }
    }
    private _hasEnterprisePermission(permissions: SitePermission[]) {
        return permissions.some(item => item.level === 'ADMIN' && item.siteCode === 'ENTERPRISE')
    }

    public async updateCurrentSiteInfo(site: SiteInfo) {
        try {
            const siteInfo = await this._httpClient.put<SiteInfo>(`${SITE_INFO_API}/${site.key}`, site).toPromise();
            this._$currentSite.next(siteInfo);
            let permissions = (await this.sitePermissionService.oSitePermissions.pipe(take(1)).toPromise()).sort(compare);
            this._$selectedSitePermission.next(
                {
                    isAdmin: !!permissions.find(per => per.siteCode === site.code && per.level == 'ADMIN'),
                    isEstimator: !!permissions.find(per => per.siteCode === site.code && per.level == 'ESTIMATOR'),
                })
        } catch (ex) {
            throw ex;
        }
    }
    public showAndHideActivator(show: boolean) {
        this._$showActivator.next(show);
    }
    public async isAllSitesSelected(): Promise<boolean> {
        const isAllSites = (await this.oCurrentSite.pipe(debounceTime(0), take(1)).toPromise())?.code === ALL_SITES.code;

        isAllSites && this._snackbar.open('Please Select a Site to Continue!', "X", {
            duration: 3000,
            panelClass: ['error-snackbar'],
            verticalPosition: 'top'
        });
        return isAllSites;
    }
    public isCalcConfigured(isConfigured: boolean) {
        this._$isCalcConfigured.next(isConfigured);
    }
    public checkSelectedPermissionIsAdmin() {
        return of(this._$selectedSitePermission.getValue()?.isAdmin);
    }

}
