import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { User } from '@discoverer/app-core/user/user.types';
import { UserDataService } from '@discoverer/app-core/user-data.service';
import { Location } from '@angular/common';
import { AuthService } from '@discoverer/app-core/gateway-auth/services/auth.service';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user'
})
export class UserComponent implements OnInit, OnDestroy {
    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _userService: UserDataService,
        private _authService: AuthService,
        private location: Location,
    ) {
    }

    async ngOnInit() {
        const user = await this._userService.getUser();
        this.user = {
            id: user.id.toString(),
            name: user.name,
            email: user.email
        }
        this._changeDetectorRef.markForCheck();
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    updateUserStatus(status: string): void {
        // Return if user is not available
        if (!this.user) {
            return;
        }

        // Update the user
        // this._userService.update({
        //     ...this.user,
        //     status
        // }).subscribe();
    }


    signOut(): void {
        localStorage.removeItem("access-token");
        localStorage.clear();
        const url = new URL(window.location.href);
        url.searchParams.delete("token");
        url.searchParams.delete("userId");
        const currentUrl = this.location.path();

        this._authService.goToLogin(currentUrl);
    };
}
