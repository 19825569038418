import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppGuardService } from './app.guard.service';
import { InitialDataResolver } from './app.resolvers';
import { AuthGuard, LandingComponent, LayoutComponent } from '@discoverer/app-core';

const routes: Routes = [
    { path: 'auth/landing', component: LandingComponent },
    { path: '', redirectTo: 'prctl', pathMatch: 'full' },
    {
        path: '',
        canActivate: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'classic'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            { path: 'prctl', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AuthGuard] },
            { path: 'prctl', outlet: 'dialog', loadChildren: () => import('./ignite/ignite.module').then(m => m.IgniteModule), canActivate: [AuthGuard] },
        ]
    }

];

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    useHash: false,
    relativeLinkResolution: 'legacy'
};

@NgModule({
    imports: [RouterModule.forRoot(routes, routerConfig)],
    exports: [RouterModule],
    providers: [AppGuardService]
})
export class AppRoutingModule {
}
