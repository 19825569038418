import { variable } from '@angular/compiler/src/output/output_ast';
import { v4 as uuidv4 } from 'uuid';

export class BaseCalculatorConfig {
    guid: string;
    version: number;
    isActive: boolean;
    isDeleted: boolean;
    isLatest: boolean;
    category: string;
    sites: ValidatedSite[];
}
export class ValidatedSite {
    code: string;
    validatedBy: string;
    validatedAt: Date;

}
export class CalculatorConfigNames extends BaseCalculatorConfig {
    jsonMetdata: string;

    constructor(guid: string) {
        super();
        this.guid = guid;
        this.version = 1;
        this.jsonMetdata = '';
        this.isActive = false;
        this.isDeleted = false;
        this.isLatest = true;
        this.sites = [];
    }
}
export class CalculatorConfig extends BaseCalculatorConfig {
    jsonMetdata: Calculator;

    constructor(guid: string) {
        super();
        this.guid = guid;
        this.version = 1;
        this.jsonMetdata = new Calculator();
        this.isActive = false;
        this.isDeleted = false;
        this.isLatest = true;
        this.sites = [];
    }

}

export class Calculator {
    name: string;
    formulas: { [key: string]: string };
    standardFields: { [key: string]: { [key: string]: boolean } };
    fields: { [key: string]: FieldInfo };
    constructor() {
        this.name = null;
        this.formulas = {};
        this.standardFields = {};
        this.fields = {};
    }
}

export class FieldInfo {
    display: string;
    order: number;
    type: 'number' | 'numeric' | 'select' | 'percent';
    variables: string[];
    inputs: string[];
    defaults: { [key: string]: number };
    options: { [key: string]: OptionInfo };
    constructor(field: {
        fieldDisplay: string,
        fieldType: 'select' | 'number' | 'numeric' | 'percent',
        variable1: string,
        variable2: string,
        variable3: string,
        variable4: string,
        variable5: string,
        inputVariable: string,
        order: number,
        options: { [key: string]: OptionInfo }
    }) {
        this.display = field.fieldDisplay;
        this.type = field.fieldType;
        this.variables = [field.variable1, field.variable2, field.variable3, field.variable4, field.variable5].filter(variable => variable);
        this.inputs = [field.inputVariable];


        this.options = field.options || {};
        this.order = field.order;
    }
}

export class OptionInfo {
    display: string;
    order: number;
    defaults: { [key: string]: number };
}
export class StandardFieldInfo extends FieldInfo {
    usedInformula?: boolean = true;
    hiddenFromTestInputs?: boolean = false;
    constructor(field: {
        fieldDisplay: string,
        fieldType: 'select' | 'number' | 'numeric' | 'percent',
        variable1: string,
        variable2: string,
        variable3: string,
        variable4: string,
        variable5: string,
        inputVariable: string,
        order: number,
        options: { [key: string]: OptionInfo }
    }) {
        super(field);
    }
}

export class CalculatorGroup {
    category: string;
    calculators: CalculatorConfig[];
}
