
import { Injectable } from '@angular/core';
import { AbstractNavigationItemsService, Navigation } from '@discoverer/app-core';
import { FuseNavigationItem } from '@discoverer/fuse/components/navigation';
import { Observable, of } from 'rxjs';
export const APP_NAVITEMS: FuseNavigationItem = {
    id: 'app',
    title: 'App',
    type: 'group',
    icon: 'heroicons_outline:chart-bar',
    children: [
        {
            id: 'estimates',
            title: 'Estimates',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/estimate/default'
        },
        // {
        //     id: 'timeStudies',
        //     title: 'Time Studies',
        //     type: 'basic',
        //     icon: '',
        //     link: '/prctl/timesStudies'
        // },
        {
            id: 'customers',
            title: 'Customers',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/customer/default/'
        },
        {
            id: 'parts',
            title: 'Parts',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/part/default'
        },
        {
            id: 'specification',
            title: 'Specs',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/specification/default'
        }
    ]
}

export const SETTINGS_NAVITEMS: FuseNavigationItem = {
    id: 'settings',
    title: 'Administration',
    type: 'group',
    icon: 'heroicons_outline:chart-bar',
    children: [
        {
            id: 'calculators',
            title: 'Calculators',
            type: 'basic',
            icon: '',
            link: '/prctl/calculators'
        },
        {
            id: 'configuration-tracker',
            title: 'Tracker',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/calculator/default'
        },
        {
            id: 'template',
            title: 'Templates',
            type: 'basic',
            icon: '',
            link: '/prctl/reports/template/default'
        },
        {
            id: 'quote-config',
            title: 'Settings',
            type: 'basic',
            icon: '',
            link: '/prctl/quote-config'
        }
    ]

}

export const ESTIMATOR_VALID_ROUTES: string[] = [
    "/prctl/reports/estimate/default",
    "/prctl/reports/customer/default",
    "/prctl/reports/part/default",
    "/prctl/reports/specification/default",
];

export const navItems: FuseNavigationItem[] = [APP_NAVITEMS];

@Injectable()
export class NavigationItemsService extends AbstractNavigationItemsService {
    loadItems(option: any): Promise<Navigation> {
        throw new Error('Method not implemented.');
    }
    public override getNavigationItems(): Observable<Navigation> {
        return of(
            {
                mainTitle: 'PRCTL',
                items: navItems
            });
    }
}

